<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Adresses IP autorisées</v-card-title>
        <v-card-subtitle>Modifier les adresses ip pouvant se connecter à l'application.</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item link v-for="(item, index) in authorized_ip" :key="index">
                    <v-list-item-title>{{ item.adress }}</v-list-item-title>
                    <v-switch v-model="item.enable" switch></v-switch>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'AuthorizedIP',
    data() {
        return {
            authorized_ip : JSON.parse(localStorage.getItem('settings')).security.authorized_ip,
            authorized_ip_backup : JSON.parse(localStorage.getItem('settings')).security.authorized_ip,
        }
    },
    methods : {
        saveSettings() {
            if (this.authorized_ip_backup !== this.authorized_ip) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.security.authorized_ip = this.authorized_ip;
                this.authorized_ip_backup = JSON.parse(JSON.stringify(this.authorized_ip));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.authorized_ip = JSON.parse(JSON.stringify(this.authorized_ip_backup));
        },
        isChanges () {
            if (JSON.stringify(this.authorized_ip_backup) !== JSON.stringify(this.authorized_ip)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>